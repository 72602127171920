import React from "react";
import BigText from "./BigText";
import Footer from "./Footer";
import TopText from "./TopText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid,
  regular,
  brands,
  light,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import g2start from "../assets/layout/g2_start.jpg";

function Slide0() {
  return (
    <div className="slide active pre-active" id="slide0" data-id="0">
      <div className="row justify-content-center align-items-center h-100">
        <div
          className="pimage"
          style={{ backgroundImage: `url(${g2start})` }}
        ></div>
        <Footer
          videoDialog
          extraButton
          extraButtonClass="go-func big-button-center"
          extraButtonFunc="1"
          extraButtonText="Tap to start"
        />
      </div>
    </div>
  );
}
export default Slide0;
