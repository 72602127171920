import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid,
  regular,
  brands,
  light,
} from "@fortawesome/fontawesome-svg-core/import.macro";
function VideoDialogButtons(props) {
  return (
    <div className="video-dialog-buttons active">
      <div className="video-dialog">
        Meet eight great people who work on Google Cloud Platform and Google
        Compute Engine solutions in the Warsaw Google Cloud office.
      </div>
    </div>
  );
}
export default VideoDialogButtons;
