import React from "react";
import { useEffect } from "react";
import ReactGA from "react-ga";

import $ from "jquery";
import Slide0 from "./Slide0";
import Slide1 from "./Slide1";

let slide = [];

function SlideContainer() {
  const childFunc = React.useRef(null);
  useEffect(() => {
    ReactGA.pageview("/start");
  }, []);
  function onTrackedVideoFrame(currentTime, duration) {
    let ile = duration - currentTime;
    $(".slide.active .timer").text(secondsToHms(ile));
  }
  function secondsToHms(secs) {
    secs = Math.round(secs);
    var hours = Math.floor(secs / (60 * 60));

    var divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);
    if (minutes * 1 < 10) {
      minutes = "0" + minutes;
    }
    var divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    var obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return minutes + ":" + seconds;
  }

  let hrs, mins, secs;
  slide[0] = function () {
    changeSlide("3", "0");
    // ReactGA.event({ category: "Devs_Start", action: "Devs_Enter_page" });

    setTimeout(function () {
      $(".video-dialog-buttons").addClass("active");
    }, 500);

    childFunc.stopPlayVideo();
  };
  function changeSlide(s1, s2) {
    let aktywny = $(".slide.active");
    $(".slide.active").removeClass("active");
    $("#slide" + s2).addClass("pre-active");
    setTimeout(function () {
      $("#slide" + s2).addClass("active");
    }, 50);
    setTimeout(function () {
      $(aktywny).removeClass("pre-active");
    }, 550);
  }

  slide[1] = function () {
    changeSlide("0", 1);
    childFunc.handlePlayVideo();
    ReactGA.event({
      category: "Devs_Video",
      action: "Humans_behind_Cloud_Start",
    }); // ReactGA.event({ category: "Start", action: "Leave" });

    let but = $("#slide1 .button-right");
    $(but).removeClass("button-circle");
    $("#slide1 .video-wrap").removeClass("active");
    let szer = but.outerWidth();
    // but.css({'width':szer});
    setTimeout(function () {
      $("#slide1 .video-wrap").addClass("active");
    }, 500);
    // setTimeout(function() {
    //  $(but).addClass('button-circle');
    // },500);
    $("#slide1 .video-wrap video").on("timeupdate", function (event) {
      onTrackedVideoFrame(this.currentTime, this.duration);
    });
    $("#slide1 .video-wrap video").on("ended", function (event) {
      $(this).off("timeupdate");
      childFunc.handleEndVideo();

      $(".video-dialog-buttons").addClass("active");
      $("#slide1 .skipAnim").addClass("d-none");
    });
  };
  slide[999] = function () {
    // $("#slide1 .video-wrap video").off("timeupdate");
    // childFunc.handleEndVideo();

    $(".video-dialog-buttons").addClass("active");
    $("#slide1 .skipAnim").addClass("d-none");
  };
  slide[2] = function () {
    changeSlide("1", 2);

    childFunc.stopPlayVideo();
  };
  slide[4] = function () {
    changeSlide("3", 4);
    childFunc.handlePlayVideo4();
    $("#slide4 .video-wrap video").on("ended", function (event) {
      changeSlide("4", 0);
    });
  };
  useEffect(() => {
    $(".go-func").on("click", function () {
      let func = $(this).attr("data-func");
      if (func == 0) {
        // ReactGA.event({ category: "Video", action: "Leave" });
      }

      slide[func]();
    });
  });
  return (
    <div>
      <Slide0 />
      <Slide1 childFunc={childFunc} changeSlide={changeSlide} />
    </div>
  );
}
export default SlideContainer;
